import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const PaddingBlock = styled.div`
  ${props => props.theme.paddingBlock};
`;

export const Loader = styled.div`
  ${props => props.theme.center};
`;

export const ButtonLoader = styled.div`
  display:flex;
  justify-content:center;
  align-items: center;
  z-index: 1;
`;

export const titleText = css`${props => props.theme.titleText}`;
export const lightText = css`${props => props.theme.lightText}`;
