import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

import { COLORS } from '../../../../shared/utils/constants';

const Container = styled.div`
  display: flex;
  padding: 45px 0 45px 20px;
`;

const Checkbox = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
  
  &+label {
    display: inline-flex;
    user-select: none;
    
    &::before {
      content: '';
      display: inline-flex;
      width: 1.5em;
      height: 1.5em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #1a1a1a;
      margin-right: 0.5em;
    }
  }
  
  &:checked+label::before {
    content: '\u2713';
    color: #1a1a1a;
    font-family: 'system-ui';
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.05em;
    height: 1.05em;
    margin-right: 0.33em;
  }
`;

const StyledAcceptTerms = styled.label`
  color: ${COLORS.BLACK};
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
`;

@inject('configStore', 'translationsStore')
@observer
class AcceptTerms extends Component {
  state = {
    termsChecked: false,
  };

  componentDidMount() {
    const {
      configStore: {
        PayByConfigs: {
          links,
          urls: { terms, privacy },
        },
      },
    } = this.props;
    const styleNode = document.createElement('style');
    styleNode.type = 'text/css';
    const css = Object.entries(links).reduce(
      (styleString, [propName, propValue]) => `${styleString}${propName}:${propValue};`,
      '',
    );
    styleNode.appendChild(
      document.createTextNode(`a {${css} cursor: pointer; text-decoration: underline; }`),
    );
    document.head.appendChild(styleNode);

    const htmlLinks = document.getElementsByTagName('a');
    Array.prototype.slice.call(htmlLinks).forEach((element) => {
      if (element.innerText === 'Terms & Conditions' || element.innerText === 'T&C') {
        element.setAttribute('href', terms);
        element.setAttribute('target', '_blank');
      } else if (element.innerText === 'Privacy Policy') {
        element.setAttribute('href', privacy);
        element.setAttribute('target', '_blank');
      }
    });
  }

  handleCheck = () => {
    const { callback } = this.props;
    const { termsChecked } = this.state;
    this.setState({
      termsChecked: !termsChecked,
    }, callback);
  }

  render() {
    const {
      translationsStore: {
        translations: { acceptTerms },
      },
    } = this.props;
    const { termsChecked } = this.state;

    return (
      <Container>
        <Checkbox onChange={() => null} type="checkbox" id="checkbox" name="checkbox" checked={termsChecked} />
        <StyledAcceptTerms
          htmlFor="checkbox"
          onClick={this.handleCheck}
          dangerouslySetInnerHTML={{ __html: `<div>${acceptTerms}</div>` }}
        />
      </Container>
    );
  }
}

export default AcceptTerms;
