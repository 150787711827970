import React from 'react';
import styled from 'styled-components';

import Header from './Header';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display:flex;
  justify-content:center;
  overflow-x: hidden;
`;

const Page = styled.div`
  width: 800px;
`;

const Layout = ({ children }) => (
  <Wrapper>
    <Page>
      <Header />
      {children}
    </Page>
  </Wrapper>
);


export default Layout;
