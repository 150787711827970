import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';

import { history } from './history';
import Layout from './shared/components/ui/Layout';
import Home from './pages/Home';
import Expired from './pages/Expired';
import PaymentConfirmation from './pages/PaymentConfirmation';

const Routes = props => (
  <Layout>
    <Router history={history}>
      <Switch>
        <Route exact component={Home} path="/" {...props} />
        <Route exact component={Home} path="/identifier/:identifier" {...props} />
        <Route component={PaymentConfirmation} path="/payment-confirmation" {...props} />
        <Route exact component={Expired} path="/expired" {...props} />
        <Route exact component={Expired} path="/invalid" {...props} />
      </Switch>
    </Router>
  </Layout>
);

export default Routes;
