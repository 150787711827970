import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-height: 50vh;
  padding: 50px;
  ${props => props.theme.center};
`;

export const textCustomStyles = css`
  text-align: center;
  ${props => props.theme.titleText};
`;
