import moment from 'moment';
import { upperCase } from 'lodash';

import { NUMBERS, PAYMENT_STATUSES } from './constants';
import { configurationsStore } from '../../store';

const getDefaultLanguage = (configurations) => {
  if (configurations.languages) {
    return configurations.languages.default
      && configurations.languages.default.toUpperCase();
  }
  return Object.keys(configurations.translations)[0] || 'EN';
};

export const isEmptyObject = data => data && !Object.keys(data).length;

export const format = (date) => {
  const {
    PayByConfigs: {
      settings: { dateFormat },
    },
  } = configurationsStore;
  const parsedDateFormat = dateFormat
    .split('/')
    .map(i => upperCase(i))
    .join('/');
  return moment(date).format(parsedDateFormat);
};

export const setHeader = (faviconUrl, title = 'PayBy client') => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);

  document.title = title;
};

export const getHelloHearerUserName = (metadata) => {
  if (metadata.first_name && metadata.last_name) {
    return `${metadata.first_name} ${metadata.last_name}`;
  }

  if (metadata.first_name) {
    return metadata.first_name;
  }

  return metadata.last_name;
};

export const getCurrencySymbolPosition = (currency, configurations) => {
  const locale = getDefaultLanguage(configurations).replace('_', '-').toUpperCase();

  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay: 'code' });
  const parts = formatter.formatToParts(NUMBERS.ZERO);
  const currencyIndex = parts.findIndex(part => part.value === currency);

  if (currencyIndex === NUMBERS.ZERO) {
    return 'before';
  }

  return 'after';
};

export const getCurrencySymbol = (currency, configurations) => {
  const locale = getDefaultLanguage(configurations).replace('_', '-').toUpperCase();

  return (0).toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).replace(/\d/g, '').trim();
};

export const getCurrency = (value, currency, configurations) => {
  const isIE10 = navigator.appVersion.indexOf('MSIE 10') !== -NUMBERS.ONE;
  const localString = getDefaultLanguage(configurations).replace('_', '-').toUpperCase();

  if (isIE10) {
    switch (currency) {
      case 'USD':
        return `$${value.toLocaleString(localString)}`;
      case 'EUR':
        return `€${value.toLocaleString(localString)}`;
      case 'GBP':
        return `£${value.toLocaleString(localString)}`;
      default:
        return value.toLocaleString(localString);
    }
  }
  return value.toLocaleString(localString, { style: 'currency', currency: `${currency}` });
};

export const formatLocale = (locale) => {
  const notFoundNum = -1;
  let localString = [];
  if (locale.indexOf('-') !== notFoundNum) {
    localString = locale.split('-');
  } else if (locale.indexOf('_') !== notFoundNum) {
    localString = locale.split('_');
  } else {
    localString[0] = locale;
  }
  localString[0] = localString[0][0].toUpperCase() + localString[0].slice(NUMBERS.ONE);
  return localString[0];
};

export const formatErrorText = (error, errorText = 'Something went wrong, please try later\nReference code: ') => {
  if (error && error.uniqueErrorIdentifier) {
    return `${errorText}\n${error.uniqueErrorIdentifier}`;
  }
  return errorText;
};

export const roundNumber = (value, accuracy) => {
  const multiplier = Math.pow(NUMBERS.TEN, accuracy);

  return (Math.round(Number(value) * multiplier) / multiplier).toFixed(accuracy);
};

export const isPaymentStatusSuccess = status => status === PAYMENT_STATUSES.SUCCESS
                                             || status === PAYMENT_STATUSES.PARTIAL_SUCCESS;

export const handlePaymentError = (translationKey, translations) => translations.payment_request_not_valid[translationKey] || 'Payment request isn\'t valid';

export const checkCurrency = (settings, paymentRequest) => {
  if (paymentRequest && paymentRequest.currency !== 'USD') {
    return paymentRequest.currency;
  }
  if (settings && settings.currency) {
    return settings.currency;
  }
  return 'USD';
};
