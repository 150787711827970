import axios from 'axios';

const API_BASE_URL_TEST = process.env.REACT_APP_API_URI;
const Axios = axios.create({ baseURL: API_BASE_URL_TEST });

export const resetAuthHeader = () => {
  delete Axios.defaults.headers.common.Authorization;
};

export const setAuthHeader = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem('jwt', accessToken);
    Axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else if (accessToken === null) {
    window.localStorage.removeItem('jwt');
    resetAuthHeader();
  }
};

const token = window.localStorage.getItem('jwt');
setAuthHeader(token);

export default Axios;
