import { css } from 'styled-components';
import { COLORS } from './constants';

const textFontStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 17px;
`;

export const theme = {
  bigTitle: css`
    ${textFontStyle};
    color: ${COLORS.TITLE_TEXT_COLOR};
    font-weight: bold;
  `,
  titleText: css`
    ${textFontStyle};
    color: ${COLORS.TITLE_TEXT_COLOR};
    font-weight: 600;
  `,
  lightText: css`
    color: ${COLORS.LIGHT_TEXT_COLOR};
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
  `,
  labelText: css`
    ${textFontStyle};
    color: ${COLORS.LABEL_TEXT_COLOR};
    font-weight: 300;
  `,
  center: css`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  paddingBlock: css`
    padding: 20px;
  `,
};
