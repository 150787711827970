import { payByClient } from '../categories';
import {
  impressionStart,
  submit,
  impressionThankYou,
  impressionExpired,
  error,
} from '../actions';
import { paymentInfo } from '../labels';

export const impression = reservationId => ({
  category: payByClient,
  action: impressionStart,
  label: reservationId,
});

export const onCTAClick = (paymentMethod, creditCard, applePay) => ({
  category: payByClient,
  action: submit,
  label: paymentInfo(paymentMethod, creditCard, applePay),
});

export const thankYouImpression = reservationId => ({
  category: payByClient,
  action: impressionThankYou,
  label: reservationId,
});

export const expiredImpression = reservationId => ({
  category: payByClient,
  action: impressionExpired,
  label: reservationId,
});

export const errorMessage = message => ({
  category: payByClient,
  action: error,
  label: message,
});
