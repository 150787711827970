import styled, { css } from 'styled-components';

export const PaddingBlock = styled.div`${props => props.theme.paddingBlock}`;
export const CardOnFileTextBlock = styled.div`
  margin-top: 20px;
`;
export const MarginBlock = styled.div`
  margin-top: 20px;
`;

export const titleText = css`${props => props.theme.titleText}`;
export const lightText = css`${props => props.theme.lightText}`;
export const dividerStyle = css`
  margin-top: 20px;
`;
