import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundaryComponent } from './shared/components/ErrorBoundary';

import Routes from './Routes';
import { theme } from './shared/utils/globalStyles';
import {
  translationsStore,
  walletStore,
  rootStore,
  configurationsStore,
} from './store';

@observer
class App extends Component {
  async componentDidMount() {
    const { setIdentifier, getConfigurations, getPaymentRequestData } = configurationsStore;
    const { setupWallet } = walletStore;
    await setIdentifier();
    await getConfigurations();
    await getPaymentRequestData();
    await setupWallet();
  }

  render() {
    return (
      <Provider
        store={rootStore}
        translationsStore={translationsStore}
        walletStore={walletStore}
        configStore={configurationsStore}
      >
        <ThemeProvider theme={theme}>
          <ErrorBoundaryComponent>
            <Routes />
          </ErrorBoundaryComponent>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
