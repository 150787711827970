import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { toJS } from 'mobx';

const StyledButton = styled(Button)`
  &&& {
    width: 100%;
    padding: 20px;
    border: none !important;
    box-shadow: 0 2px 7px 0 rgb(0 0 0 / 15%);
    bottom: 0;
    color: black;
    background-color: ${props => props.background_color};

    && span {
      padding: 15px;
    }
  }
`;

const DisabledButton = styled(StyledButton)`
  && span {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

const ActiveButton = styled(StyledButton)`
  && span {
    ${props => props.styled_span}
    display: unset !important;
  }
`;

@inject('configStore')
@observer
class ButtonItem extends Component {
  renderButton = () => {
    const {
      configStore: { PayByConfigs },
      label,
      onPress,
      disabled,
    } = this.props;

    if (disabled) {
      return (
        <DisabledButton
          disabled={disabled}
          variant="outlined"
          disableRipple
          background_color={toJS(PayByConfigs.background.color)}
        >
          {label || ''}
        </DisabledButton>
      );
    }

    return (
      <ActiveButton
        disabled={disabled}
        variant="outlined"
        onClick={onPress}
        disableRipple
        background_color={toJS(PayByConfigs.background.color)}

        styled_span={toJS(PayByConfigs.buttons.complete)}
      >
        {label || ''}
      </ActiveButton>
    );
  };

  render() {
    return this.renderButton();
  }
}

export default ButtonItem;
