import { observable, action } from 'mobx';
import { get } from 'lodash';
import {
  NUMBERS,
  ROUTE_NAMES,
  API_ENDPOINTS,
  PAYMENT_STATUSES,
} from '../shared/utils/constants';
import { history } from '../history';
import { sendEvent } from '../analytics/analytics';
import { errorMessage, onCTAClick } from '../analytics/trigers/mainClient';
import { apiPost, apiPut } from '../shared/utils/apiConfig';
import { configurationsStore } from './Configurations.store';
import { translationsStore } from './Translations.store';
import { rootStore } from './RootStore';
import { isEmptyObject } from '../shared/utils/helpers';
import { walletStore } from './Wallet.store';

const DECLINE_PAYMENT_ERROR = 'DECLINE_PAYMENT_ERROR';
const DEFAULT_TIP = 0;
const ERROR_CODE_6012 = 6012;

class WalletV3Store {
  @observable
  walletSDK = null;

  @observable
  isAlternativePayed = false;

  @observable
  isConfirmationPage = false;

  @observable
  checkoutData = {
    isReady: false,
    isOverlayShown: false,
  };

  @action
  setLang = (language) => {
    this.walletSDK.setLocale(language);
  };

  @action
  initWallet = async () => {
    if (isEmptyObject(configurationsStore.paymentRequestConfig)) {
      return;
    }
    this.walletSDK = window.mycheck.wallet;

    await walletStore.setWalletSDK(this.walletSDK);
    await this._connectAndInitWalletV3SDK();
  };

  @action
  setPaymentDetails = async ({ paymentsData }) => {
    this.walletSDK.setPaymentDetails(paymentsData);
  };

  @action
  completePaymentRequest = async () => {
    sendEvent(onCTAClick(configurationsStore.paymentRequestConfig.payment_request.paymentType));
    if (this.checkoutData.details && this.checkoutData.details.type === 'instant') {
      return;
    }
    if (this.checkoutData.isReady) {
      rootStore.setButtonLoading(true);
      this.checkoutData = {
        ...this.checkoutData,
        isOverlayShown: true,
      };
    }

    try {
      const pm = await this.walletSDK.getPaymentMethod();
      if (rootStore.email) {
        await apiPut(`${API_ENDPOINTS.PAYMENT_REQUESTS}${configurationsStore.identifier}`, {
          identifier: configurationsStore.identifier,
          metadata: {
            user_email: rootStore.email,
          },
        });
      }
      if (!this.isAlternativePayment) {
        const response = await apiPost(API_ENDPOINTS.COMPLETE_PAYMENT_REQUEST, {
          cc_token: pm.cc_token,
          identifier: configurationsStore.identifier,
          additional_charge: Number(rootStore.tip || DEFAULT_TIP),
          encrypted_cvv: pm.encrypted_cvv,
          fingerprint: pm.fingerprint,
          language: translationsStore.currentLanguage,
        });

        const payment = get(response, 'data.confirmation.payment');
        const saleAwaitingConfirmation = get(payment, 'SALE.transaction.isAsync');
        const sale3dsAwaitingConfirmation = get(payment, 'SALE_3DS.transaction.isAsync');
        const authAwaitingConfirmation = get(payment, 'AUTH.transaction.isAsync');
        const auth3dsAwaitingConfirmation = get(payment, 'AUTH_3DS.transaction.isAsync');

        if (
          sale3dsAwaitingConfirmation === NUMBERS.ONE
          || saleAwaitingConfirmation === NUMBERS.ONE
          || authAwaitingConfirmation === NUMBERS.ONE
          || auth3dsAwaitingConfirmation === NUMBERS.ONE
        ) {
          return;
        }
        history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
      }
    } catch (err) {
      const formatErrorText = (text) => { // eslint-disable-line
        return text.replace('.', '.\n');
      };
      const textError6012 = translationsStore.translations.error6012
        || 'Unfortunately, your payment was declined. Please contact our call centre to obtain a new link';

      sendEvent(errorMessage(err.message));
      rootStore.setButtonLoading(false);
      rootStore.renderError({
        button: 'Confirm',
        errorObject: err,
        text: err.response.data.code === ERROR_CODE_6012 && formatErrorText(textError6012),
      });
      this.checkoutData = {
        ...this.checkoutData,
        isOverlayShown: false,
      };
    }
  };

  @action
  createWalletScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = process.env.REACT_APP_V3_SDK_URL;

    this._loadWalletCSS();

    return script;
  };

  @action
  _connectAndInitWalletV3SDK = async () => {
    const { userConfigs, PayByConfigs } = configurationsStore;
    const acceptedCards = get(PayByConfigs, 'settings.acceptedCards', [])
      .slice();
    const alternatives = get(PayByConfigs, 'settings.alternatives', [])
      .slice()
      .filter((alternativeWallet) => {
        const hideTypes = get(alternativeWallet, 'options.hideOn.paymentType', [])
          .slice();
        return !hideTypes.includes(
          configurationsStore.paymentRequestConfig.payment_request.workflow,
        );
      })
      .map(wallet => wallet.name);
    const paymentData = await walletStore.getPaymentsData();

    window.mycheck.auth.connect({
      publishableKey: walletStore.publishableKey,
      accessToken: userConfigs.accessToken,
    });

    this.walletSDK.on('paymentCompleted', this._handlePaymentCompleted);
    this.walletSDK.on('checkoutReady', this._handleCheckoutReady);
    this.walletSDK.on('error', this._handleError);

    const walletSettings = {
      settings: {
        view: this.walletSDK.views.CHECKOUT,
        addCardAutofocus: true,
        locale: translationsStore.currentLanguage,
        acceptedPaymentMethods: alternatives,
        acceptedCreditcards: acceptedCards,
      },
      payment_details: paymentData,
    };

    if (PayByConfigs.settings.merchant_id) {
      walletSettings.settings.merchant_id = PayByConfigs.settings.merchant_id;
    }

    this.walletSDK.init(walletSettings);
  }

  @action
  _handleError = (error) => {
    this.checkoutData = { ...this.checkoutData, isReady: false };
    if (error.reason !== 'CVV_EXPIRED') {
      rootStore.renderError({
        button: 'Confirm',
        errorObject: error,
      });
    }
    if (error !== DECLINE_PAYMENT_ERROR) {
      console.error(error);
    }
  }

  @action
  _handleCheckoutReady = (isReady, checkoutDetails) => {
    rootStore.setButtonLoading(false);
    console.log({ isReady, checkoutDetails });
    this.checkoutData.isReady = isReady;
    this.checkoutData = {
      isReady,
      isOverlayShown: false,
      details: checkoutDetails,
    };
  }

  @action
  _handlePaymentCompleted = async (response) => {
    this.checkoutData = { ...this.checkoutData, isReady: false };
    rootStore.setButtonLoading(false);
    if (this.checkoutData.details.type === 'instant') {
      await this._afterAlternativeComplete(response);
      return;
    }
    if (response && response.status === PAYMENT_STATUSES.SUCCESS) {
      history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
    } else {
      console.log({ response });
      this._handleError(DECLINE_PAYMENT_ERROR);
    }
  }

  @action
  _afterAlternativeCompleteV3 = async (response) => {
    // eslint-disable-next-line no-console
    console.log('afterAlternativePaymentComplete', response);
    try {
      this.isAlternativePayment = true;
      if (response && (response.status === 'SUCCESS' || response.status === 'PENDING')) {
        const url = new URL(window.location);
        const typeFromURL = url.searchParams.get('mycheck-transaction-id');
        // eslint-disable-next-line no-console
        console.log('status pending');
        if (typeFromURL && !this.isAlternativePayed) {
          this.isAlternativePayed = true;
          const pm = await this.walletSDK.getPaymentMethod();

          await apiPost(API_ENDPOINTS.COMPLETE_PAYMENT_REQUEST, {
            cc_token: pm.cc_token,
            identifier: configurationsStore.identifier,
            transaction_id: response.transaction_id,
            additional_charge: Number(rootStore.tip || DEFAULT_TIP),
          });

          localStorage.setItem('message', `ALTERNATIVE_SUCCESS_${new Date().getTime()}`);
          history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
        }
      } else {
        localStorage.setItem(
          'message',
          JSON.stringify({ ...response, msg: `ALTERNATIVE_FAILURE_${new Date().getTime()}` }),
        );
        throw new Error();
      }
    } catch (err) {
      rootStore.setButtonLoading(false);
      rootStore.renderError({
        button: 'Confirm',
        errorObject: err,
      });
    }
  }

  @action
  _loadWalletCSS = () => {
    const css = document.createElement('link');
    css.rel = 'stylesheet';
    css.href = process.env.REACT_APP_V3_SDK_CSS;
    document.getElementsByTagName('head')[0].appendChild(css);
  }
}

export const walletV3Store = new WalletV3Store();
