import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { get } from 'lodash';

import {
  PaddingBlock, DividerWrapper, titleText, lightText, dividerStyle,
} from './styles';

import { history } from '../../history';
import { thankYouImpression } from '../../analytics/trigers/mainClient';
import { sendEvent } from '../../analytics/analytics';
import { isEmptyObject } from '../../shared/utils/helpers';
import Text from '../../shared/components/ui/Text';
import Divider from '../../shared/components/ui/Divider';
import Counters from '../../shared/components/Counters';
import ConfirmationDetails from './components/ConfirmationDetails';
import { ROUTE_NAMES } from '../../shared/utils/constants';

const SCROLL_TO_NUM = 0;

@inject('store', 'translationsStore', 'configStore')
@observer
class PaymentConfirmation extends Component {
  componentDidMount() {
    const { store } = this.props;

    sendEvent(thankYouImpression(get(store, 'identifier', 'empty')));
    window.scrollTo(SCROLL_TO_NUM, SCROLL_TO_NUM);
  }

  render() {
    const {
      configStore: { paymentRequestConfig, PayByConfigs },
      translationsStore: { translations },
    } = this.props;

    if (isEmptyObject(paymentRequestConfig)) {
      history.push(ROUTE_NAMES.INVALID);
      return null;
    }

    const {
      settings: {
        showComponents: {
          confirmation: {
            thanks, details, subtotal, tip, total,
          },
        },
      },
    } = PayByConfigs;

    return (
      <>
        <PaddingBlock>
          {thanks && (
            <>
              <Text
                contentText={translations.confirmationHeader}
                customStyles={titleText}
                styles={{ textTransform: 'uppercase' }}
                size="run2"
              />
              <Text
                contentText={translations.confirmationText}
                customStyles={lightText}
                size="run3"
              />
              <Divider style={dividerStyle} />
            </>
          )}
        </PaddingBlock>
        {details && <ConfirmationDetails />}
        <Counters isPaymentConfirmation />
        {(subtotal || tip || total) && (
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
        )}
      </>
    );
  }
}

export default PaymentConfirmation;
