import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

// run1 size 13
// run2 size 12 bold
// run3 size 11

const StyledText = styled.div`
  &&&{
    color: ${({ texts, size, textColor }) => textColor || (texts && texts[size].color)};
    font-family: ${({ texts, size }) => texts && texts[size].fontFamily};
    font-size: ${({ texts, size }) => texts && texts[size].size};
  }
  ${props => props.customStyles};
`;

@inject('configStore')
@observer
class Text extends Component {
  renderText = () => {
    const {
      contentText,
      configStore: {
        PayByConfigs: { texts },
      },
      size = 'run3',
      textColor,
      customStyles,
    } = this.props;
    return (
      <StyledText
        texts={texts}
        size={size}
        textColor={textColor}
        customStyles={customStyles}
      >
        {contentText}
      </StyledText>
    );
  };

  render() {
    return this.renderText();
  }
}

export default Text;
