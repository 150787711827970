import { observable, action } from 'mobx';
import {
  NUMBERS,
  ROUTE_NAMES,
  API_ENDPOINTS,
  PAYMENT_FLOW_TYPES,
} from '../shared/utils/constants';
import { history } from '../history';
import { sendEvent } from '../analytics/analytics';
import { errorMessage } from '../analytics/trigers/mainClient';
import { apiGet } from '../shared/utils/apiConfig';
import {
  isEmptyObject,
  isPaymentStatusSuccess,
  setHeader,
  handlePaymentError,
} from '../shared/utils/helpers';
import { setAuthHeader } from '../shared/utils/axiosConfig';
import { translationsStore } from './Translations.store';
import { walletStore } from './Wallet.store';
import { rootStore } from './RootStore';

class ConfigurationsStore {
  @observable
  isLoading = true;

  @observable
  userConfigs = {};

  @observable
  paymentRequestConfig = {};

  @observable
  PayByConfigs = {};

  @observable
  identifier = '';

  @observable
  paymentError = '';

  @action
  setIdentifier = () => {
    const IDENTIFIER_PARAM_NAME = 'identifier';
    const currentUrl = new URL(window.location);
    const identifierFromURL = currentUrl.searchParams.get(IDENTIFIER_PARAM_NAME);

    if (identifierFromURL) {
      this.identifier = identifierFromURL;
    }

    if (currentUrl.pathname.includes(IDENTIFIER_PARAM_NAME)) {
      const pathFragments = currentUrl.pathname.split('/');
      const identifierParamIndex = pathFragments
        .findIndex(fragment => fragment === IDENTIFIER_PARAM_NAME);

      if (identifierParamIndex !== -NUMBERS.ONE) {
        this.identifier = pathFragments[identifierParamIndex + NUMBERS.ONE];
      }
    }

    if (!this.identifier) {
      history.push(ROUTE_NAMES.INVALID);
    }
  }

  @action
  _setPaymentRequestConfig = (data) => {
    this.paymentRequestConfig = data;
  }

  @action
  _setUserConfig = (user) => {
    setAuthHeader(user.accessToken);
    this.userConfigs = user;
  }

  @action
  _resetShowedComponents = (paymentType) => {
    if (paymentType === PAYMENT_FLOW_TYPES.NON_INTEGRATED) {
      const { main, confirmation } = this.PayByConfigs.settings.showComponents;
      main.subtotal = false;
      main.tax = false;
      main.tip = false;
      main.userEmail = false;
      confirmation.subtotal = false;
      confirmation.tax = false;
      confirmation.tip = false;
    }
  }

  @action
  getPaymentRequestData = async () => {
    let paymentStatus = null;
    if (this.identifier) {
      try {
        const paymentResponse = await apiGet(`${API_ENDPOINTS.PAYMENT_REQUEST}${this.identifier}`);
        rootStore.setExpired(false);
        if (paymentResponse && paymentResponse.data) {
          const { data } = paymentResponse;
          const { user, payment_request: { status, type } } = data;

          if (paymentResponse.data.status.toLowerCase() === 'error') {
            this.paymentError = handlePaymentError(
              paymentResponse.data.info.toLowerCase(), translationsStore.translations,
            );
            history.push(ROUTE_NAMES.INVALID);
          }

          if (this._isUserAndPaymentDataSet()) {
            this._setPaymentRequestConfig(data);
            this._setUserConfig(user);
            this._resetShowedComponents(type);
            this.isLoading = false;
          }

          if (isPaymentStatusSuccess(status)) {
            history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
          }

          paymentStatus = status;
        }
      } catch (error) {
        rootStore.setExpired(true);
        sendEvent(errorMessage(error.message));
        this.paymentError = handlePaymentError(
          error.response.data.info.toLowerCase(), translationsStore.translations,
        );
        this.isLoading = false;
        if (error.response.data.info.toLowerCase() === 'payment_expired') {
          history.push(ROUTE_NAMES.EXPIRED);
        } else {
          history.push(ROUTE_NAMES.INVALID);
        }
      }
      return paymentStatus;
    }

    return null;
  }

  @action
  getPaymentRequestStatus = async () => {
    let counter = NUMBERS.ZERO;
    const interval = setInterval(() => {
      counter += NUMBERS.ONE;
      const paymentStatus = this.getPaymentRequestData();

      if (counter === (NUMBERS.TEN * NUMBERS.THREE)) {
        clearInterval(interval);
      }

      if (isPaymentStatusSuccess(paymentStatus)) {
        clearInterval(interval);
      }
    }, NUMBERS.THOUSAND * NUMBERS.TWO);
  }

  @action
  getConfigurations = async () => {
    let response;
    await translationsStore.getDefaultTranslations();
    if (isEmptyObject(this.PayByConfigs) && this.identifier) {
      try {
        response = await apiGet(`${API_ENDPOINTS.PAYMENT_REQUEST}${this.identifier}/settings`);
      } catch (error) {
        if (!this.identifier) {
          this.isLoading = false;
        }
        history.push(ROUTE_NAMES.INVALID);
        sendEvent(errorMessage(error.message));
        return null;
      }
    }
    if (response && response.data) {
      const { languages } = response.data.settings.PayBy;

      this.PayByConfigs = response.data.settings.PayBy;
      walletStore.setPublishableKey(response.data.settings.publishable_key);
      if (languages && languages.base_url) {
        await translationsStore.loadAllLanguages(response.data.settings);
        translationsStore.setTranslations();
        translationsStore.setAllTranslations();
      } else {
        await translationsStore.setFallbackTranslation(this.PayByConfigs);
      }
      setHeader(this.PayByConfigs.assets.favicon, response.data.settings.business_details.name);
    }

    if (
      history.location.pathname === ROUTE_NAMES.INVALID
      || history.location.pathname === ROUTE_NAMES.EXPIRED
    ) {
      this.isLoading = false;
    }

    return null;
  };

  @action
  _isUserAndPaymentDataSet = () => isEmptyObject(this.paymentRequestConfig)
    && isEmptyObject(this.userConfigs)
    && this.isLoading
}

export const configurationsStore = new ConfigurationsStore();
