import { observable, action } from 'mobx';
import { walletStore } from './Wallet.store';
import { configurationsStore } from './Configurations.store';

const SUPPORTED_LANGUAGES_URL = 'https://mycheck-general-settings.s3-eu-west-1.amazonaws.com/payby/supported_languages.json';
const DEFAULT_LANGUAGE_URL = 'https://translations-v1.mycheckapp.com/payby/v1/en.json';

class TranslationsStore {
  @observable
  translations = {};

  @observable
  currentLanguage = 'en';

  @observable
  allTranslations = {
    default: {},
  };

  @observable
  supportedLanguages = [];

  @observable
  langWithLabelMap = {};

  @observable
  langLoaded = false

  @action
  loadAllLanguages = async (settings) => {
    const baseUrl = settings.PayBy.languages.base_url;
    this._setDefaultLang(settings);
    this._setSupportedLanguages(settings);
    await this._setLangWithLabelMap();
    if (this.supportedLanguages && this.supportedLanguages.length) {
      for (const translation of this.supportedLanguages) {
        try {
          await this._addTranslationToAllTranslations(translation, baseUrl);
        } catch (err) {
          //  do nothing so far
        }
      }
    }
    this.langLoaded = true;
  };

  @action
  getDefaultTranslations = async () => {
    const defaultTranslationResponse = await fetch(DEFAULT_LANGUAGE_URL);
    const defaultTranslation = await defaultTranslationResponse.json();
    this.translations = { ...this.translations, ...defaultTranslation };
  }

  @action
  changeLanguage = async (language) => {
    if (
      this.supportedLanguages.find(lang => lang === language)
      && !!this.allTranslations[language]
    ) {
      this.currentLanguage = language;
      this.translations = { ...this.translations, ...this.allTranslations[language] };
      walletStore.setLang(language);
    }

    return true;
  };

  @action
  setTranslations = () => {
    this.translations = { ...this.translations, ...this.allTranslations[this.currentLanguage] };
  }

  @action
  setAllTranslations = () => {
    this.allTranslations = { ...this.allTranslations, default: this.translations };
  }

  @action
  setFallbackTranslation = async (configurations) => {
    const defaultLang = Object.keys(configurations.translations)[0];
    const translationsResponse = await fetch(configurations.translations[defaultLang]);
    const fallbackTranslations = await translationsResponse.json();
    this.translations = { ...this.translations, ...fallbackTranslations };
  }

  @action
  _setSupportedLanguages = (settings) => {
    this.supportedLanguages = settings.PayBy.languages.supports.filter(lang => lang !== 'invalid-language');
  };

  @action
  _setDefaultLang = (settings) => {
    const isDefaultInSupports = settings.PayBy.languages.supports
      .find(item => item === settings.PayBy.languages.default);
    if (isDefaultInSupports) {
      this.currentLanguage = settings.PayBy.languages.default || 'en';
    } else {
      this.currentLanguage = 'en';
    }
  }

  @action
  _addTranslationToAllTranslations = async (translation, baseUrl) => {
    let currTranslation = await this._currTranslationResponse(baseUrl, translation);
    currTranslation = await currTranslation.json();
    const { translations } = configurationsStore;

    if (translations && translations[translation]) {
      const changedTranslationResponse = await fetch(
        translations[translation],
      );
      const changedTranslation = await changedTranslationResponse.json();
      currTranslation = changedTranslation
        ? { ...currTranslation, ...changedTranslation }
        : currTranslation;
    }

    this.allTranslations = { ...this.allTranslations, [translation]: currTranslation };
  };

  @action
  _setLangWithLabelMap = async () => {
    const labelsResponse = await fetch(SUPPORTED_LANGUAGES_URL);
    this.langWithLabelMap = await labelsResponse.json();
  };

  _currTranslationResponse = (baseUrl, translation) => fetch(
    this._generateTranslationLink(baseUrl, translation),
  );

  _generateTranslationLink = (baseUrl, lang) => `${baseUrl}${lang}.json`;
}

export const translationsStore = new TranslationsStore();
