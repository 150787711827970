import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { initializeGA } from './analytics/analytics';

initializeGA();

ReactDOM.render(<App />, document.getElementById('root'));
