import { observable, action } from 'mobx';
import { get } from 'lodash';
import {
  NUMBERS,
  ROUTE_NAMES,
  API_ENDPOINTS,
  PAYMENT_STATUSES,
} from '../shared/utils/constants';
import { history } from '../history';
import { sendEvent } from '../analytics/analytics';
import { errorMessage, onCTAClick } from '../analytics/trigers/mainClient';
import { apiPost, apiPut } from '../shared/utils/apiConfig';
import { isEmptyObject } from '../shared/utils/helpers';
import { configurationsStore } from './Configurations.store';
import { translationsStore } from './Translations.store';
import { rootStore } from './RootStore';
import { walletStore } from './Wallet.store';

const DEFAULT_TIP = 0;
const WALLET_V3_TAG_NAME = 'mycheck-wallet-v3';

class WalletV2Store {
  @observable
  walletSDK = null;

  @observable
  isAlternativePayed = false;

  @observable
  isConfirmationPage = false;

  @observable
  walletV2ReadyData = {
    formReady: false,
    cardReady: false,
  }

  @action
  setLang = (language) => {
    this.walletSDK.setLocale(language);
  }

  @action
  initWallet = async () => {
    if (isEmptyObject(configurationsStore.paymentRequestConfig)) {
      return;
    }
    this.walletSDK = window.mycheckWallet;

    await walletStore.setWalletSDK(this.walletSDK);
    await this._connectAndInitWalletV2SDK();
  }

  @action
  setPaymentDetails = async ({ paymentsData }) => {
    this.walletSDK.setPayment(paymentsData);
  };


  @action
  completePaymentRequest = async () => {
    sendEvent(onCTAClick(configurationsStore.paymentRequestConfig.payment_request.paymentType));
    if (this.walletV2ReadyData.formReady || this.walletV2ReadyData.cardReady) {
      rootStore.setButtonLoading(true);
    }

    try {
      const pm = await window.mycheckWallet.getCardToken();
      if (rootStore.email) {
        await apiPut(`${API_ENDPOINTS.PAYMENT_REQUEST}${configurationsStore.identifier}`, {
          identifier: configurationsStore.identifier,
          metadata: {
            user_email: rootStore.email,
          },
        });
      }
      if (!this.isAlternativePayment) {
        const response = await apiPost(API_ENDPOINTS.COMPLETE_PAYMENT_REQUEST, {
          cc_token: pm,
          identifier: configurationsStore.identifier,
          additional_charge: Number(rootStore.tip || DEFAULT_TIP),
          encrypted_cvv: pm.encrypted_cvv,
          fingerprint: pm.fingerprint,
          language: translationsStore.currentLanguage,
        });

        rootStore.setButtonLoading(false);

        const payment = get(response, 'data.confirmation.payment');
        const saleAwaitingConfirmation = get(payment, 'SALE.transaction.isAsync');
        const sale3dsAwaitingConfirmation = get(payment, 'SALE_3DS.transaction.isAsync');
        const authAwaitingConfirmation = get(payment, 'AUTH.transaction.isAsync');
        const auth3dsAwaitingConfirmation = get(payment, 'AUTH_3DS.transaction.isAsync');

        if (
          sale3dsAwaitingConfirmation === NUMBERS.ONE
          || saleAwaitingConfirmation === NUMBERS.ONE
          || authAwaitingConfirmation === NUMBERS.ONE
          || auth3dsAwaitingConfirmation === NUMBERS.ONE
        ) {
          return;
        }
        history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
      }
    } catch (err) {
      sendEvent(errorMessage(err.message));
      rootStore.setButtonLoading(false);
      rootStore.renderError({
        button: 'Confirm',
        errorObject: err,
      });
    }
  };

  @action
  createWalletScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = process.env.REACT_APP_V2_SDK_URL;

    return script;
  }

  @action
  _connectAndInitWalletV2SDK = async () => {
    const { userConfigs, PayByConfigs } = configurationsStore;
    const acceptedCards = get(PayByConfigs, 'settings.acceptedCards', [])
      .slice().filter(card => card !== 'diners');
    let alternatives = get(PayByConfigs, 'settings.alternatives', [])
      .slice();
    alternatives = alternatives
      .filter((alternativeWallet) => {
        const hideTypes = get(alternativeWallet, 'options.hideOn.paymentType', [])
          .slice();
        return !hideTypes.includes(
          configurationsStore.paymentRequestConfig.payment_request.workflow,
        );
      })
      .map(wallet => wallet.name);

    const walletSettings = {
      acceptedCards,
      accessToken: userConfigs.accessToken,
      acceptedAlternativeWallets: alternatives,
      publishableKey: walletStore.publishableKey,
      checkoutPage: true,
      local: translationsStore.currentLanguage,
      events: {
        afterSelectCreditCard: (token) => {
          if (token && token.length) {
            this.walletV2ReadyData = { ...this.walletV2ReadyData, cardReady: true };
          }
        },
        afterFormIsReady: (data) => {
          this.walletV2ReadyData = { ...this.walletV2ReadyData, formReady: data.isValid };
        },
        after3DsComplete: (response) => {
          if (response && response.status === PAYMENT_STATUSES.SUCCESS) {
            history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
          }
        },
        afterAlternativePaymentComplete:
          async response => this._afterAlternativeCompleteV2(response),
      },
    };
    this.walletSDK.init(WALLET_V3_TAG_NAME, walletSettings);
  }

  @action
  _afterAlternativeCompleteV2 = async (response) => {
    // eslint-disable-next-line no-console
    console.log('afterAlternativePaymentComplete', response);
    try {
      this.isAlternativePayment = true;
      if (
        response
        && (response.status === 'SUCCESS' || response.status === 'PENDING')
      ) {
        const url = new URL(window.location);
        const typeFromURL = url.searchParams.get('mycheck-transaction-id');
        // eslint-disable-next-line no-console
        console.log('status pending');
        if (typeFromURL && !this.isAlternativePayed) {
          this.isAlternativePayed = true;
          const pm = await window.mycheckWallet.getCardToken();

          await apiPost(API_ENDPOINTS.COMPLETE_PAYMENT_REQUEST, {
            cc_token: pm,
            identifier: configurationsStore.identifier,
            transaction_id: response.transaction_id,
            additional_charge: Number(rootStore.tip || DEFAULT_TIP),
          });

          localStorage.setItem(
            'message',
            `ALTERNATIVE_SUCCESS_${new Date().getTime()}`,
          );
          history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
        }
      } else {
        localStorage.setItem(
          'message',
          JSON.stringify({
            ...response,
            msg: `ALTERNATIVE_FAILURE_${new Date().getTime()}`,
          }),
        );
        throw new Error();
      }
    } catch (err) {
      rootStore.setButtonLoading(false);
      rootStore.renderError({
        button: 'Confirm',
        errorObject: err,
      });
    }
  };
}

export const walletV2Store = new WalletV2Store();
