import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import {
  Wrapper, Row, RowWithMarginTop, titleText, totalTextStyle, dividerStyle,
} from './styles';

import { NUMBERS } from '../../utils/constants';
import {
  checkCurrency, getCurrency, roundNumber,
} from '../../utils/helpers';
import Tip from '../Tip';
import Text from '../ui/Text';
import Divider from '../ui/Divider';

@inject('store', 'configStore', 'walletStore', 'translationsStore')
@observer
class Counters extends Component {
  state = {
    total: '',
    tip: '',
    subtotal: '',
  };

  componentDidMount() {
    const {
      store: {
        tip = '',
      },
      configStore: {
        paymentRequestConfig: {
          payment_request: { amount, metadata, metadata: { tax } },
        },
        PayByConfigs: { settings: { showComponents: { confirmation } } },
      },
      isPaymentConfirmation = false,
    } = this.props;
    let tipAmount = tip;
    let subtotal;
    let total;

    if (metadata.additional_charge) {
      tipAmount = tip ? tip + metadata.additional_charge : metadata.additional_charge;
    }

    if (tax && confirmation.tax) {
      subtotal = isPaymentConfirmation
        ? amount - Number(tax)
        : amount - Number(tax) - Number(tipAmount);
      total = subtotal + Number(tax) + Number(tipAmount);
    } else {
      subtotal = isPaymentConfirmation
        ? amount
        : amount - Number(tipAmount);
      total = subtotal + Number(tipAmount);
    }

    this.setState({ total, tip: tipAmount, subtotal });
  }

  changeTip = (value) => {
    const {
      configStore: {
        paymentRequestConfig: {
          payment_request, // eslint-disable-line
          payment_request: { metadata: { tax } },
        },
        PayByConfigs: {
          settings: { showComponents: { confirmation } },
        },
      },
      walletStore: {
        setPayments,
      },
    } = this.props;
    const { subtotal } = this.state;
    const isPercent = value.indexOf('%');
    const isNotANumber = isNaN(Number(value.replace(/,/g, '').match(/[\d.]+(\.\d+)?/g)));
    let tipNumber;

    if (isPercent !== -NUMBERS.ONE) {
      if (tax && confirmation.tax) {
        tipNumber = (subtotal + Number(tax)) / NUMBERS.HUNDRED * Number(value.replace(/%/g, ''));
      } else {
        tipNumber = subtotal / NUMBERS.HUNDRED * Number(value.replace(/%/g, ''));
      }
    } else if (isNotANumber) {
      tipNumber = NUMBERS.ZERO;
    } else {
      tipNumber = Number(value.replace(/,/g, '').match(/[\d.]+(\.\d+)?/g));
    }
    let total;

    if (tax && confirmation.tax) {
      total = subtotal + Number(tax) + tipNumber;
    } else {
      total = subtotal + tipNumber;
    }

    setPayments({
      tip: tipNumber ? roundNumber(tipNumber, NUMBERS.TWO).toString() : '',
      total: total.toFixed(NUMBERS.FRACTION_DIGITS)
        .toString(),
    });

    this.setState({ total, tip: tipNumber.toString() });
  };

  renderSubtotal = (configurations) => {
    const {
      configStore: {
        PayByConfigs: { settings },
        paymentRequestConfig: {
          payment_request, // eslint-disable-line
        },
      },
      translationsStore: {
        translations: { subtotalAmount },
      },
    } = this.props;
    const { subtotal } = this.state;
    const currency = checkCurrency(settings, payment_request);

    return (
      <RowWithMarginTop>
        <Text contentText={subtotalAmount} size="run4" customStyles={totalTextStyle} />
        <Text
          contentText={getCurrency(subtotal, currency, configurations)}
          customStyles={titleText}
          size="run4"
        />
      </RowWithMarginTop>
    );
  };

  renderTax = (configurations) => {
    const {
      configStore: {
        paymentRequestConfig: {
          payment_request, // eslint-disable-line
          payment_request: { metadata: { tax } },
        },
        PayByConfigs: {
          settings,
          settings: { showComponents: { confirmation } },
        },
      },
      translationsStore: {
        translations: { taxLabel = 'Tax' },
      },
    } = this.props;
    const currency = checkCurrency(settings, payment_request);

    if (confirmation.tax && tax) {
      return (
        <RowWithMarginTop>
          <Text contentText={taxLabel} size="run4" customStyles={totalTextStyle} />
          <Text
            contentText={
              getCurrency(
                Number(tax.replace(/,/g, '')),
                currency,
                configurations,
              )
            }
            customStyles={titleText}
            size="run4"
          />
        </RowWithMarginTop>
      );
    }

    return null;
  }

  renderTip = (configurations) => {
    const {
      configStore: {
        PayByConfigs: { settings },
        paymentRequestConfig: {
          payment_request, // eslint-disable-line
        },
      },
      translationsStore: {
        translations: { tipLabel = 'Tip' },
      },
    } = this.props;
    const { tip } = this.state;
    const currency = checkCurrency(settings, payment_request);

    return (
      <RowWithMarginTop>
        <Text contentText={tipLabel} size="run4" customStyles={totalTextStyle} />
        <Text
          contentText={
            getCurrency(
              Number(tip.replace(/,/g, '')),
              currency,
              configurations,
            )
          }
          customStyles={titleText}
          size="run4"
        />
      </RowWithMarginTop>
    );
  };

  renderTotal = (configurations) => {
    const {
      configStore: {
        PayByConfigs: { settings },
        paymentRequestConfig: {
          payment_request, // eslint-disable-line
        },
      },
      translationsStore: {
        translations: { TotalAmount },
      },
    } = this.props;
    const { total } = this.state;
    const currency = checkCurrency(settings, payment_request);

    return (
      <Row>
        <Text contentText={TotalAmount} size="run2" customStyles={totalTextStyle} />
        <Text
          contentText={getCurrency(total, currency, configurations)}
          customStyles={titleText}
          size="run2"
        />
      </Row>
    );
  };

  render() {
    const {
      configStore: {
        PayByConfigs, PayByConfigs: { settings: { showComponents: { main, confirmation } } },
      },
      isPaymentConfirmation = false,
    } = this.props;
    const displayedComponents = isPaymentConfirmation ? confirmation : main;

    return (
      <Wrapper>
        {displayedComponents.tip && !isPaymentConfirmation && <Tip changeTip={this.changeTip} />}
        {displayedComponents.subtotal && this.renderSubtotal(PayByConfigs)}
        {displayedComponents.tax && this.renderTax(PayByConfigs)}
        {displayedComponents.tip && this.renderTip(PayByConfigs)}
        {(displayedComponents.subtotal || displayedComponents.tip) && displayedComponents.total && (
          <Divider style={dividerStyle} />
        )}
        {displayedComponents.total && this.renderTotal(PayByConfigs)}
      </Wrapper>
    );
  }
}

export default Counters;
