import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get } from 'lodash';

import styled from 'styled-components';
import {
  Wrapper, PaddingBlock, Loader, ButtonLoader, titleText,
} from './styles';

import {
  NUMBERS, PAYMENT_FLOW_TYPES, SWAL_ERROR_ICON_NAMES, termsAcceptPopupText,
} from '../../shared/utils/constants';
import { sendEvent } from '../../analytics/analytics';
import { impression } from '../../analytics/trigers/mainClient';
import { isEmptyObject, getHelloHearerUserName } from '../../shared/utils/helpers';
import Text from '../../shared/components/ui/Text';
import Title from '../../shared/components/ui/Title';
import Button from '../../shared/components/ui/Button';
import Counters from '../../shared/components/Counters';
import CreditCard from './components/CreditCard';
import AcceptTerms from './components/AcceptTerms';
import ReservationDetails from '../PaymentConfirmation/components/ReservationDetails';
import EmailInput from '../../shared/components/ui/EmailInput';
import { SECTION_HEADER_2, SECTION_HEADER_3 } from '../../shared/utils/defaultTranslations';

const LoaderOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
`;

@inject('store', 'translationsStore', 'walletStore', 'configStore')
@observer
class Home extends Component {
  state = {
    termsChecked: false,
  }

  componentDidMount() {
    const { configStore } = this.props;

    setTimeout(() => {
      sendEvent(impression(get(configStore, 'identifier', 'expired')));
    }, NUMBERS.ONE_SECOND);
  }

  termsCallback = () => {
    const { termsChecked } = this.state;

    this.setState({ termsChecked: !termsChecked });
  }

  onPressButton = () => {
    const {
      store: { renderError },
      walletStore: { completePaymentRequests },
      translationsStore: { translations },
    } = this.props;
    const { termsChecked } = this.state;

    if (termsChecked) {
      completePaymentRequests();
      return null;
    }

    return renderError({
      text: translations.termsAcceptPopupText
        ? translations.termsAcceptPopupText
        : termsAcceptPopupText,
      button: 'OK',
      icon: SWAL_ERROR_ICON_NAMES.WARNING,
    });
  }

  renderHome = () => {
    const {
      store: {
        buttonLoading,
      },
      configStore: {
        paymentRequestConfig: {
          payment_request: { workflow, metadata, type },
          business_details: { name },
        },
        PayByConfigs: {
          settings: {
            showComponents: {
              main: {
                hello,
                reservationDetails,
                paymentMethodsTitle,
                paymentMethods,
                userEmail,
                terms,
              },
            },
          },
          texts: { helloHeader },
          buttons: { complete },
        },
      },
      translationsStore: {
        translations,
      },
      walletStore,
    } = this.props;
    const url = new URL(window.location);
    const typeFromURL = url.searchParams.get('mycheck-transaction-id');

    const payIsAvailable = () => {
      if (walletStore.walletVersion && walletStore.walletVersion.toLowerCase() === 'v3') {
        return walletStore.walletV3Store.checkoutData.isReady;
      }
      return (walletStore.walletV2Store.walletV2ReadyData.cardReady
        || walletStore.walletV2Store.walletV2ReadyData.formReady);
    };

    let paymentType = workflow || type;
    let buttonContent;

    if (paymentType === PAYMENT_FLOW_TYPES.PAY_AND_STORE_FILE) {
      paymentType = PAYMENT_FLOW_TYPES.PAY_AND_STORE_FILE;
    }

    if (paymentType === PAYMENT_FLOW_TYPES.CARD_ON_FILE) {
      paymentType = PAYMENT_FLOW_TYPES.CARD_ON_FILE;
    }

    if (paymentType === PAYMENT_FLOW_TYPES.NON_INTEGRATED) {
      paymentType = PAYMENT_FLOW_TYPES.NON_INTEGRATED;
    }

    if (!translations.paymentType[paymentType]) {
      paymentType = PAYMENT_FLOW_TYPES.PREPAID;
    }

    buttonContent = translations.paymentType[paymentType];

    if (buttonLoading) {
      buttonContent = (
        <ButtonLoader>
          <CircularProgress color="inherit" size={24} />
        </ButtonLoader>
      );
    }

    return (
      <Wrapper style={typeFromURL && { display: 'none' }}>
        {walletStore.walletV3Store.checkoutData.isOverlayShown && <LoaderOverlay />}
        <PaddingBlock>
          {hello && (
            <>
              <Text
                contentText={translations.helloHeader.replace(/{USER_NAME}/g, getHelloHearerUserName(metadata))}
                customStyles={[titleText, helloHeader]}
                textColor={complete.backgroundColor}
                size="run2"
              />
              <Text
                contentText={
                  (paymentType === 'non_integrated' && !!translations.nonIntegratedHelloText
                    ? translations.nonIntegratedHelloText
                    : translations.helloText
                  ).replace('{BUSINESS_NAME}', name || '')
                }
                customStyles={[titleText]}
                size="run3"
              />
            </>
          )}
        </PaddingBlock>

        {reservationDetails && <ReservationDetails />}

        <Counters />

        {paymentMethodsTitle
          && <Title title={`2. ${translations.paymentMethodsHeader || SECTION_HEADER_2}`} />
        }
        {paymentMethods && <CreditCard />}
        {userEmail && <EmailInput />}
        <Title title={`3. ${translations.completePaymentHeader || SECTION_HEADER_3}`} />
        {terms && <AcceptTerms callback={this.termsCallback} />}

        <Button
          disabled={!payIsAvailable()}
          onPress={this.onPressButton}
          label={buttonContent}
        />
      </Wrapper>
    );
  };

  render() {
    const {
      configStore: { paymentRequestConfig },
      translationsStore: { translations },
      walletStore: { isAlternativePayment },
    } = this.props;

    if (!isEmptyObject(paymentRequestConfig)
      && !isEmptyObject(translations) && !isAlternativePayment) {
      return this.renderHome();
    }

    return (
      <Loader>
        <CircularProgress color="inherit" />
      </Loader>
    );
  }
}

export default Home;
