import React from 'react';

import {
  Wrapper, titleText, titleTextWithMarginBottom, lightText, dividerStyle,
} from './styles';

import { format, getHelloHearerUserName } from '../../../../shared/utils/helpers';
import { configurationsStore } from '../../../../store';
import Text from '../../../../shared/components/ui/Text';
import Divider from '../../../../shared/components/ui/Divider';

const ConfirmationDetails = () => {
  const {
    paymentRequestConfig: {
      payment_request: { metadata },
    },
  } = configurationsStore;
  const { adult, child } = JSON.parse(metadata.guestCount || '{}');
  return (
    <Wrapper>
      <Text
        contentText={getHelloHearerUserName(metadata)}
        customStyles={titleTextWithMarginBottom}
        size="run1"
      />
      <Text contentText={metadata.property_name} customStyles={titleText} size="run3" />
      <Text
        contentText={`${format(metadata.checkInTime)} - ${format(metadata.checkOutTime)}`}
        customStyles={lightText}
        size="run3"
      />
      <Text
        contentText={`${metadata.numberOfNights} Nights, ${adult} Adults, ${child} Children`}
        customStyles={lightText}
        size="run3"
      />
      <Divider style={dividerStyle} />
    </Wrapper>
  );
};

export default ConfirmationDetails;
