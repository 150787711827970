export const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  HUNDRED: 100,
  THOUSAND: 1000,
  ONE_SECOND: 1000,
  FRACTION_DIGITS: 2,
};

export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  RED: '#FF0000',
  TITLE_TEXT_COLOR: '#3B271A',
  LIGHT_TEXT_COLOR: '#121212',
  LABEL_TEXT_COLOR: '#7D7D7D',
};

export const termsAcceptPopupText = 'Please agree to the terms and conditions';

export const ROUTE_NAMES = {
  EXPIRED: '/expired',
  PAYMENT_CONFIRMATION: '/payment-confirmation',
  INVALID: '/invalid',
};

export const API_ENDPOINTS = {
  PAYMENT_REQUEST: 'prm/api/v1/payment-request/',
  PAYMENT_REQUESTS: 'prm/api/v1/payment-requests/',
  COMPLETE_PAYMENT_REQUEST: 'prm/api/v1/payment-request/complete',
};

export const PAYMENT_FLOW_TYPES = {
  NON_INTEGRATED: 'non_integrated',
  PREPAID: 'prepaid',
  CARD_ON_FILE: 'card_on_file',
  PAY_AND_STORE_FILE: 'pay_and_store_card',
};

export const PAYMENT_STATUSES = {
  SUCCESS: 'SUCCESS',
  PARTIAL_SUCCESS: 'PARTIAL_SUCCESS',
};

export const REQUEST_STATUS_CODES = {
  BAD_REQUEST: 400,
};

export const SWAL_ERROR_ICON_NAMES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};
