import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import { NUMBERS } from '../../utils/constants';
import { checkCurrency, getCurrencySymbol, getCurrencySymbolPosition } from '../../utils/helpers';
import InputCurrency from './InputCurrency';

const Wrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  border-width: ${({ customstyle }) => (customstyle.borderSize)};
  border-style: ${({ customstyle }) => (customstyle.borderType)};
  border-color: ${({ customstyle }) => (customstyle.borderColor)};
`;
const StyledButton = styled(Button)`
  &&& {
    width: 100%;
    border-radius: 0;
    border-right: ${({ lastbutton, customstyle }) => (lastbutton === 'true' ? '0px' : `${customstyle.borderSize} ${customstyle.borderType} ${customstyle.borderColor}`)};
    background-color: ${({ activebutton, customstyle }) => (activebutton === 'true' ? customstyle.backgroundColor : 'none')};
    color: ${({ activebutton, customstyle }) => (activebutton === 'true' ? customstyle.color : (customstyle.colorDefault || 'none'))};
    font-family: ${({ customstyle }) => customstyle.fontFamily};
    font-size: ${({ customstyle }) => customstyle.fontSize};
     @media (max-width: 500px) {
        font-size: 10px;
     }
  }
`;


@inject('configStore')
@observer
class ButtonGroup extends Component {
  state = {
    clickedId: 0,
  }

  get styles() {
    const {
      configStore: {
        PayByConfigs,
        PayByConfigs: { texts: { run1: { fontFamily } } },
      },
    } = this.props;
    const defaultStyle = {
      backgroundColor: 'black',
      color: 'white',
      borderSize: '1px',
      borderType: 'solid',
      borderColor: 'black',
      text: {
        fontFamily,
        size: '12pt',
      },
    };
    const buttonStyleFromAPI = toJS(PayByConfigs.buttons.tip) || {};
    const buttonStyle = { ...defaultStyle, ...buttonStyleFromAPI };

    return {
      backgroundColor: buttonStyle.backgroundColor,
      color: buttonStyle.color,
      borderSize: buttonStyle.borderSize,
      borderType: buttonStyle.borderType,
      borderColor: buttonStyle.borderColor,
      fontFamily: buttonStyle.text.fontFamily,
      fontSize: buttonStyle.text.size,
      colorDefault: buttonStyle.text.color,
    };
  }

  handleClick = index => (event) => {
    const { changeValue } = this.props;
    const label = event.target.innerText;
    this.setState({ clickedId: index });
    changeValue(label);
  }

  render() {
    const {
      configStore: {
        PayByConfigs,
        PayByConfigs: { settings },
        paymentRequestConfig: { payment_request }, // eslint-disable-line
      },
      buttons, customLastButton, changeValue,
    } = this.props;
    const { clickedId } = this.state;

    const currency = checkCurrency(settings, payment_request);

    if (!buttons) {
      return null;
    }

    return (
      <Wrapper customstyle={this.styles}>
        {buttons.map((label, i) => {
          const isLastButton = buttons.length - NUMBERS.ONE === i;
          const isActiveButton = clickedId === i;

          if (isLastButton && isActiveButton && customLastButton) {
            return (
              <InputCurrency
                key={`${label}${i + NUMBERS.ONE}`}
                currency={getCurrencySymbol(currency, PayByConfigs)}
                isPrefix={getCurrencySymbolPosition(currency, PayByConfigs) === 'before'}
                onChange={({ target }) => changeValue(target.value)}
              />
            );
          }

          return (
            <StyledButton
              key={`${label}${i + NUMBERS.ONE}`}
              customstyle={this.styles}
              name={label}
              type="button"
              activebutton={isActiveButton.toString()}
              lastbutton={isLastButton.toString()}
              onClick={this.handleClick(i)}
            >
              {label}
            </StyledButton>
          );
        })}
      </Wrapper>
    );
  }
}

export default ButtonGroup;
