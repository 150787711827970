import { observable, action } from 'mobx';
import swal from 'sweetalert';
import { SWAL_ERROR_ICON_NAMES } from '../shared/utils/constants';
import { translationsStore } from './Translations.store';
import { formatErrorText, isEmptyObject } from '../shared/utils/helpers';

class RootStore {
  @observable
  buttonLoading = false;

  @observable
  translations = {};

  @observable
  tip = ''

  @observable
  email = '';

  @observable
  expired = false;

  @action
  setExpired = (value) => {
    this.expired = value;
  }

  @action
  setEmail = async (value) => {
    this.email = value;
  };

  @action
  setButtonLoading = (value) => {
    this.buttonLoading = value;
  }

  @action
  setTip = (tip) => {
    this.tip = tip;
  }

  @action
  renderError = ({
    text,
    button,
    icon,
    fallbackTranslation,
    errorObject,
  }) => {
    try {
      const { error } = translationsStore.translations;
      const errorMsg = !isEmptyObject(error)
        ? formatErrorText(errorObject, error.text)
        : formatErrorText(errorObject, 'Something went wrong, please try later');
      return swal({
        text: text || errorMsg,
        button: button || 'Ok',
        icon: icon || SWAL_ERROR_ICON_NAMES.ERROR,
        dangerMode: true,
      });
    } catch (error) {
      return swal({
        text: fallbackTranslation,
        button: button || 'Ok',
        icon: icon || SWAL_ERROR_ICON_NAMES.ERROR,
        dangerMode: true,
      });
    }
  }
}

export const rootStore = new RootStore();
