import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import { Wrapper, textCustomStyles } from './styles';

import { NUMBERS } from '../../shared/utils/constants';
import { expiredImpression } from '../../analytics/trigers/mainClient';
import { sendEvent } from '../../analytics/analytics';
import Text from '../../shared/components/ui/Text';

@inject('store', 'translationsStore', 'configStore')
@observer
class Expired extends Component {
  componentDidMount() {
    const { store } = this.props;

    setTimeout(() => {
      sendEvent(expiredImpression(get(store, 'identifier', 'empty')));
    }, NUMBERS.ONE_SECOND);
  }

  render() {
    const { translationsStore, configStore } = this.props;

    if (configStore.isLoading) {
      return null;
    }

    return (
      <Wrapper>
        <Text
          size="run2"
          contentText={configStore.paymentError || translationsStore.translations.expired || 'The link is expired.'}
          customStyles={textCustomStyles}
        />
      </Wrapper>
    );
  }
}

export default Expired;
