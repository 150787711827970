import api from './axiosConfig';

const handleError = (err, endpoint) => {
  // eslint-disable-next-line no-console
  console.log('endpoint Error', endpoint);
  // eslint-disable-next-line no-console
  console.log('Error', JSON.parse(JSON.stringify(err)));
  throw err;
};

export async function apiGet(endpoint, options) {
  try {
    return await api.get(endpoint, options || {});
  } catch (error) {
    handleError(error, endpoint);
  }
  return null;
}

export async function apiPost(endpoint, data, options) {
  try {
    return await api.post(endpoint, data, options || {});
  } catch (error) {
    handleError(error, endpoint);
  }
  return null;
}

export async function apiPut(endpoint, data, options) {
  try {
    return await api.put(endpoint, data, options || {});
  } catch (error) {
    handleError(error, endpoint);
  }
  return null;
}
