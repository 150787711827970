import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 10px 0;
`;

const CreditCard = () => (
  <Wrapper>
    <div id="mycheck-wallet-v3" />
    <div id="alternative" />
  </Wrapper>
);

export default CreditCard;
