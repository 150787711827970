import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CheckIcon from '@material-ui/icons/Check';

import { ReactComponent as Globe } from '../../../assets/images/globe@1.5x.svg';

import { COLORS } from '../../utils/constants';
import { formatLocale } from '../../utils/helpers';

const Wrapper = styled.div`
  position: relative;
`;

const OptionsBox = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  min-width: 156px;
  padding: 0px;
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
  background: white;
  color: #333333;
  ${props => props.style};
  &:before {
    content: ' ';
    width: 0px;
    height: 0px;
    top: -12px;
    position: absolute;
    right: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid white;
  }
`;

const OptionItem = styled.div`
  padding: 10px 20px;
  display: flex;
  color: #000000;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  ${props => props.activeStyle};
`;

const LangBtn = styled.div`
  padding: 10px 20px;
  display:flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const GlobeLogo = styled(Globe)`
  & path {
    fill: ${props => props.color};
    stroke: ${props => props.color};
  }
`;

const defaultFunction = () => {};

@inject('configStore')
@observer
class LanguagePicker extends Component {
  state = {
    showDropdown: false,
  };

  toggleDropdown = () => {
    this.setState(currState => ({
      showDropdown: !currState.showDropdown,
    }));
  };

  hideDropdown = () => {
    this.setState({
      showDropdown: false,
    });
  };

  selectLanguage = (language) => {
    const { onOptionSelected = defaultFunction, allTranslations } = this.props;

    if (allTranslations[language]) {
      onOptionSelected(language);
      this.hideDropdown();
    }
  };

  render() {
    const {
      configStore: { configurations },
      allTranslations,
      selectedOption,
      options,
      labels,
    } = this.props;
    const { showDropdown } = this.state;

    return (
      <Wrapper>
        <ClickAwayListener touchEvent={false} onClickAway={this.hideDropdown}>
          <LangBtn onClick={this.toggleDropdown}>
            <GlobeLogo color={get(configurations, '', COLORS.WHITE)} />
            {formatLocale(selectedOption)}
          </LangBtn>
        </ClickAwayListener>
        <OptionsBox style={{ display: showDropdown ? 'block' : 'none' }}>
          {options.filter(option => !!labels[option]).map(option => (
            <OptionItem
              key={`${option}-item`}
              activeStyle={!allTranslations[option] && { color: 'rgba(0,0,0,0.25)' }}
              onClick={() => this.selectLanguage(option)}
            >
              {labels[option].label}
              {selectedOption === option && <CheckIcon fontSize="small" />}
            </OptionItem>
          ))}
        </OptionsBox>
      </Wrapper>
    );
  }
}

export default LanguagePicker;
