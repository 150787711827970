import React from 'react';

import {
  PaddingBlock, CardOnFileTextBlock, MarginBlock, titleText, lightText, dividerStyle,
} from './styles';
import { format } from '../../../../shared/utils/helpers';
import {
  translationsStore, configurationsStore,
} from '../../../../store';
import Title from '../../../../shared/components/ui/Title';
import Divider from '../../../../shared/components/ui/Divider';
import Text from '../../../../shared/components/ui/Text';
import { SECTION_HEADER_1 } from '../../../../shared/utils/defaultTranslations';

const ReservationDetails = () => {
  const {
    paymentRequestConfig: {
      payment_request: {
        metadata,
        type,
        workflow,
      },
    },
    PayByConfigs: {
      settings: {
        showComponents: { main: { reservationDetailsTitle } },
      },
    },
  } = configurationsStore;
  const { translations } = translationsStore;
  const { adult, child } = JSON.parse(metadata.guestCount || '{}');
  const isNonIntegratedType = type === 'non_integrated';
  const details = typeof metadata.payment_request_details === 'string'
    ? JSON.parse(metadata.payment_request_details) : metadata.payment_request_details;

  const isTypeCardOnFile = workflow === 'card_on_file' || type === 'card_on_file';

  return (
    <>
      {reservationDetailsTitle && <Title title={`1. ${translations.dataHeader || SECTION_HEADER_1}`} />}
      {type !== 'dine_payment' ? (
        <PaddingBlock>
          {isNonIntegratedType ? (
            <>
              <Text
                contentText={details.title}
                customStyles={titleText}
                size="run3"
              />
              <Text
                contentText={details.details}
                customStyles={lightText}
                size="run3"
              />
            </>
          ) : (
            <>
              <Text contentText={metadata.property_name} customStyles={titleText} size="run2" />
              <Text
                contentText={`${format(metadata.checkInTime)} - ${format(metadata.checkOutTime)}`}
                customStyles={lightText}
                size="run3"
              />
              <Text
                contentText={`${metadata.numberOfNights} Nights, ${adult} Adults, ${child} Children`}
                customStyles={lightText}
                size="run3"
              />
            </>
          )}

          <Divider style={dividerStyle} />

          {isTypeCardOnFile && (
            <CardOnFileTextBlock>
              <Text
                contentText={translations.card_on_file_text}
                customStyles={lightText}
                size="run5"
              />
              <Divider style={dividerStyle} />
            </CardOnFileTextBlock>
          )}
        </PaddingBlock>
      ) : (
        <MarginBlock />
      )}
    </>
  );
};

export default ReservationDetails;
