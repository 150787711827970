import styled, { css } from 'styled-components';

export const Wrapper = styled.div`padding: 0 20px 20px 20px`;

export const dividerStyle = css`margin-top: 20px`;
export const titleText = css`${props => props.theme.titleText}`;
export const lightText = css`${props => props.theme.lightText}`;
export const titleTextWithMarginBottom = css`
  ${props => props.theme.titleText};
  margin-bottom: 10px;
`;
