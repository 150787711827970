import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import Input from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const StyledInput = styled(Input)`
  width: 100%;
  border-radius: 0;
  background-color: black;
  color: white;
  padding: 6px 8px 6px 8px;
  text-align: right;
  outline: none;
  border: none;
  ${props => props.style};
  @media (max-width: 500px) {
    font-size: 10px!important;
  }
`;

@inject('configStore')
@observer
class InputCurrency extends Component {
  get styles() {
    const {
      configStore: { PayByConfigs: { buttons, texts: { run1: { fontFamily } } } },
    } = this.props;
    const defaultStyle = {
      backgroundColor: 'black',
      color: 'white',
      text: {
        fontFamily,
        size: '12pt',
      },
    };
    const buttonStyleFromAPI = toJS(buttons.tip) || {};
    const buttonStyle = { ...defaultStyle, ...buttonStyleFromAPI };

    return {
      ...buttonStyle,
      fontFamily: buttonStyle.text.fontFamily,
      fontSize: buttonStyle.text.size,
    };
  }

  render() {
    const {
      value, onChange, currency, isPrefix, placeholder,
    } = this.props;
    const customPlaceholder = placeholder || (isPrefix ? `${currency} 0` : `0 ${currency}`);

    return (
      <StyledInput
        style={this.styles}
        autoFocus
        mask={createNumberMask({
          prefix: isPrefix ? currency : '',
          suffix: !isPrefix ? ` ${currency}` : '',
          thousandsSeparatorSymbol: ',',
          allowDecimal: true,
          decimalSymbol: '.',
        })}
        placeholder={customPlaceholder}
        maxLength={18}
        inputMode="decimal"
        value={value ? currency + value : value}
        onChange={onChange}
      />
    );
  }
}

export default InputCurrency;
