import { observable, action } from 'mobx';
import {
  ROUTE_NAMES,
} from '../shared/utils/constants';
import { history } from '../history';
import { isEmptyObject } from '../shared/utils/helpers';
import { configurationsStore } from './Configurations.store';
import { rootStore } from './RootStore';

import { walletV2Store } from './WalletV2.store';
import { walletV3Store } from './WalletV3.store';

const WALLET_VERSION_2 = 'v2';
const WALLET_VERSION_3 = 'v3';
const DEFAULT_TIP = 0;

const getReturnUrl = () => {
  let url = `${window.location.origin}${window.location.pathname}`;
  if (!url.includes('identifier')) {
    url += `identifier/${new URL(window.location).searchParams.get('identifier')}`;
  }

  return url;
};

class WalletStore {
  @observable
  walletVersion = WALLET_VERSION_2;

  @observable
  walletSDK = null;

  @observable
  walletV2Store = walletV2Store;

  @observable
  walletV3Store = walletV3Store;

  @observable
  publishableKey = process.env.REACT_APP_KEY;

  @action
  setupWallet = () => {
    if (configurationsStore.PayByConfigs && configurationsStore.PayByConfigs.settings) {
      this.walletVersion = configurationsStore.PayByConfigs.settings.wallet_version
        || WALLET_VERSION_2;
      this._loadWalletScript(this.walletVersion);
    }
  }

  @action
  setPublishableKey = (pkey) => {
    this.publishableKey = pkey;
  }

  @action
  setLang = (language) => {
    this.walletSDK.setLocale(language);
  }

  @action
  setWalletSDK = (walletSDK) => {
    this.walletSDK = walletSDK;
  }

  @action
  completePaymentRequests = () => {
    if (this.walletVersion === WALLET_VERSION_2) {
      this.walletV2Store.completePaymentRequest();
    }
    if (this.walletVersion === WALLET_VERSION_3) {
      this.walletV3Store.completePaymentRequest();
    }
  }

  @action
  getPaymentsData = async (total) => {
    let totalAmount;
    const defaultPaymentDetails = {
      currency: 'USD',
      amount: '0.0',
    };

    if (configurationsStore.identifier) {
      if (!total || isEmptyObject(configurationsStore.paymentRequestConfig)) {
        return defaultPaymentDetails;
      }
      totalAmount = total
        || configurationsStore.paymentRequestConfig.payment_request.amount.toString();
      this.isConfirmationPage = new URLSearchParams(window.location.search).get('confirmation');

      if (this.isConfirmationPage) {
        await configurationsStore.getPaymentRequestStatus();
      }

      return {
        currency: configurationsStore.PayByConfigs.settings.currency,
        amount: totalAmount,
        return_url: getReturnUrl(),
        callback_url: configurationsStore.PayByConfigs.settings.callback_url,
        metadata: {
          payment_request_identifier: configurationsStore.identifier,
          additional_charge: Number(rootStore.tip || DEFAULT_TIP),
        },
      };
    }

    return defaultPaymentDetails;
  }

  @action
  setPayments = async ({ tip, total }) => {
    const paymentsData = await this.getPaymentsData(total);
    rootStore.setTip(tip);
    if (this.walletVersion === WALLET_VERSION_2) {
      this.walletV2Store.setPaymentDetails({ paymentsData });
    }
    if (this.walletVersion === WALLET_VERSION_3) {
      this.walletV3Store.setPaymentDetails({ paymentsData });
    }
  };

  @action
  _initWallet = (walletVersion) => {
    if (isEmptyObject(configurationsStore.paymentRequestConfig)) {
      return;
    }
    if (walletVersion === WALLET_VERSION_2) {
      this.walletV2Store.initWallet();
    }
    if (walletVersion === WALLET_VERSION_3) {
      this.walletV3Store.initWallet();
    }
    window.addEventListener('storage', this._receiveMessageFromOtherWindowInstance);
  };

  @action
  _receiveMessageFromOtherWindowInstance = (ev) => {
    if (ev.key === 'message') {
      const message = ev.newValue;
      if (message.includes('ALTERNATIVE_SUCCESS')) {
        history.push(ROUTE_NAMES.PAYMENT_CONFIRMATION);
      } else {
        try {
          const msgObj = JSON.parse(message);
          if (msgObj.msg && msgObj.msg.includes('ALTERNATIVE_FAILURE')) {
            delete msgObj.msg;
            console.log('afterAlternativePaymentComplete', msgObj);
            rootStore.renderError({
              button: 'Confirm',
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  @action
  _loadWalletScript = (walletVersion) => {
    const script = (walletVersion === WALLET_VERSION_2 && this.walletV2Store.createWalletScript())
      || (walletVersion === WALLET_VERSION_3 && this.walletV3Store.createWalletScript());

    script.onload = this._initWallet.bind(this, this.walletVersion);
    document.body.appendChild(script);
  }
}

export const walletStore = new WalletStore();
