import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
`;
const Tip = styled.div`
  ${props => props.style.tip};
`;
const StyledInput = styled.input`
  margin-top: 10px;
  width: calc(100% - 22px);
  padding: 10px;
  ${props => props.style.email};
  outline: none;
`;


@inject('configStore', 'translationsStore', 'store')
@observer
class EmailInput extends Component {
  get styles() {
    const {
      configStore: {
        PayByConfigs: {
          inputs: { tip, email },
        },
      },
    } = this.props;

    return {
      tip: { color: tip.text.color, fontFamily: tip.text.fontFamily, fontSize: tip.text.size },
      email: {
        background: email.background,
        borderColor: email.borderColor,
        borderWidth: email.borderSize,
        borderStyle: email.borderType,
        borderRadius: email.borderRadius,
        color: email.text.color,
        fontFamily: email.text.fontFamily,
        fontSize: email.text.size,
      },
    };
  }

  onChange = (event) => {
    const { store } = this.props;

    store.setEmail(event.target.value);
  };

  render() {
    const {
      translationsStore: {
        translations: { emailLabel, emailPlaceholder },
      },
    } = this.props;
    return (
      <Wrapper>
        <Tip style={this.styles}>{emailLabel}</Tip>
        <StyledInput
          style={this.styles}
          placeholder={emailPlaceholder}
          onChange={this.onChange}
        />
      </Wrapper>
    );
  }
}

export default EmailInput;
