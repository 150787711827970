import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { get } from 'lodash';

import LanguagePicker from './LanguagePicker';
import { COLORS } from '../../utils/constants';
import { isEmptyObject } from '../../utils/helpers';

const StyledToolbar = styled(Toolbar)`
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexedBox = styled.div`
  display: flex;
  flex: 1;
  ${props => props.styles};
`;

@inject('configStore', 'translationsStore')
@observer
class Header extends Component {
  render() {
    const {
      configStore: {
        PayByConfigs,
      },
      translationsStore: {
        currentLanguage,
        supportedLanguages,
        langWithLabelMap,
        langLoaded,
        changeLanguage,
        allTranslations,
      },
    } = this.props;

    return PayByConfigs && !isEmptyObject(PayByConfigs) ? (
      <AppBar
        position="static"
        style={{
          background: get(PayByConfigs, 'header.backgroundColor', COLORS.WHITE),
          position: 'sticky',
          top: 0,
        }}
      >
        <StyledToolbar>
          <FlexedBox />
          <FlexedBox styles={{ justifyContent: 'center' }}>
            <img
              src={get(PayByConfigs, 'assets.logo', '')}
              style={
                {
                  height: get(PayByConfigs, 'assets.height', ''),
                }
              }
              alt="logo"
            />
          </FlexedBox>
          <FlexedBox styles={{ justifyContent: 'flex-end' }}>
            {langLoaded && (
              <LanguagePicker
                selectedOption={currentLanguage}
                allTranslations={allTranslations}
                options={supportedLanguages}
                labels={langWithLabelMap}
                onOptionSelected={changeLanguage}
              />
            )}
          </FlexedBox>
        </StyledToolbar>
      </AppBar>
    ) : null;
  }
}

export default Header;
