import React from 'react';
import styled, { css } from 'styled-components';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { COLORS } from '../../utils/constants';
import Text from './Text';
import { configurationsStore } from '../../../store/Configurations.store';

const Wrapper = styled.div`
  height: 36px;
  padding: 0 20px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(to right, ${({ bgColor }) => bgColor} 55%, transparent 45%);

  :after {
    content "";
    border: 18px solid ${COLORS.WHITE};
    border-left: 18px solid transparent;
    position: absolute;
    left: 51%;

    @media (max-width: 380px) {
      left: 61%;
    }
  }

  @media (max-width: 380px) {
    background: linear-gradient(to right, ${({ bgColor }) => bgColor} 70%, transparent 30%);
  }
`;

const Title = observer(({ title }) => {
  const backgroundColor = toJS(configurationsStore.PayByConfigs.buttons.complete.backgroundColor);
  const textColor = toJS(configurationsStore.PayByConfigs.buttons.complete.color);
  return (
    <Wrapper bgColor={backgroundColor}>
      <Text
        textColor={textColor}
        contentText={title || ''}
        customStyles={css`${props => props.theme.bigTitle}`}
      />
    </Wrapper>
  );
});


export default Title;
