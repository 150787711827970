import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

const StyledDivider = styled.div`
  border-bottom: ${({ dividerStyle: { width, style, color } }) => `${width} ${style} ${color}`};
  ${props => props.customStyle};
`;

@inject('configStore')
@observer
class Divider extends Component {
  render() {
    const { configStore: { PayByConfigs: { divider } }, style } = this.props;

    return divider && <StyledDivider dividerStyle={divider} customStyle={style} />;
  }
}

export default Divider;
