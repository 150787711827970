import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { NUMBERS } from '../../utils/constants';

const styles = theme => ({
  root: {
    maxWidth: 600,
  },
  centered: {
    margin: '0 auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(NUMBERS.TEN),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children, classes } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Grid className={[classes.root, classes.centered]} container>
          <Grid className={classes.centered} item>
            <h2>503: It seems service is temporarry down :(</h2>
            <h4>Please contact support team. Thank you!</h4>
          </Grid>
        </Grid>
      );
    }

    return children;
  }
}

export const ErrorBoundaryComponent = withStyles(styles)(ErrorBoundary);
