import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ButtonGroup from '../ui/ButtonGroup';
import Text from '../ui/Text';


@inject('configStore', 'translationsStore')
@observer
class Tip extends Component {
  render() {
    const {
      configStore: {
        PayByConfigs: {
          buttons: { tip },
        },
      },
      translationsStore: {
        translations: { tipTitle = 'LEAVE A TIP', noTipButton = 'no tip', customTipButton = 'custom' },
      },
      changeTip,
    } = this.props;
    const tipButtonsValue = (tip && tip.value) || ['10%', '15%', '20%'];
    const tipButtons = [noTipButton, ...tipButtonsValue, customTipButton];

    return (
      <div>
        <Text contentText={tipTitle} size="run4" />
        <ButtonGroup
          customLastButton
          buttons={tipButtons}
          changeValue={changeTip}
        />
      </div>
    );
  }
}

export default Tip;
