import styled, { css } from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.LIGHT_TEXT_COLOR};
  font-family: 'Proxima Nova', sans-serif;
  font-size: 13px;
  line-height: 16;
`;

export const RowWithMarginTop = styled(Row)`
  margin-top: 10px;
`;

export const titleText = css`
  ${props => props.theme.titleText}
`;

export const totalTextStyle = css`
  font-size: 12pt;
  font-weight: 600;
  line-height: 17px;
`;

export const dividerStyle = css`
  margin-top: 10px;
  margin-bottom: 10px;
`;
